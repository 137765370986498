<template>
  <router-view v-if="ready"/>
</template>

<script>
import { state } from '@shared/utils/storeUtils.mjs';
import { initSalesforce } from '@/plugins/salesforce';
import { siteIds } from '@/constants/base/siteMap';
import { setSegment } from '@/plugins/tracking';

export default {
  name: 'Onboarding',
  data() {
    return {
      ready: false,
      siteInfo: null,
    };
  },
  computed: {
    baseLang: state('env', 'baseLang'),
    site: state('env', 'site'),
    env: state('env', 'env'),
    isMBP() {
      return [siteIds.GGPUKE, siteIds.EVPUKE].includes(this.site);
    },
    isSalesforce() {
      return [siteIds.GGPNL, siteIds.GGPRO].includes(this.site);
    },
  },
  watch: {
    $route() {
      this.uid += 1;
    },
  },
  async mounted() {
    // const a = {
    //   "Code": "ACCOUNT_LOCKED",
    //   "Description": "Your account was suspended.",
    //   "CustomerErrorCode": "OASIS_BAN_EXIST",
    //   "CustomerErrorParameters": ["0018"]
    // }
    // const b = { error: true, code: a.Code, desc: a.Description,  key: a.CustomerErrorCode, CustomerErrorParameters: a.CustomerErrorParameters };
    // console.log('error :::::::::::::::::::::::::::::: ' , ApiErrorController.proxy({ site: this.site, code: b.code, customCode: b.key} ));
    // const a = {
    //   "error": true,
    //   "code": 400,
    //   "desc": "Your account was suspended.",
    //   "key": "OASIS_BAN_EXIST",
    //   "CustomerErrorParameters": [
    //     "0018"
    //   ],
    // };

    this.$store.commit('env/setTheme', 'dark');
    if(!this.isMBP) await this.$services.captcha.reCaptchaInitialize();
    if (this.isSalesforce) initSalesforce(this.site, this.baseLang);
    setSegment();
    this.ready = true;
  },
  beforeDestroy() {
    (/** @type {ModelController} */this.$model).remove(this.site);
  },
};
</script>

<style scoped>

</style>