import { siteIds } from '@/constants/base/siteMap';

export const initSalesforce = (site, baseLang) => {
  if (![siteIds.GGPNL, siteIds.GGPRO].includes(site) || process.env.VUE_APP_ENV === 'local') return;

  (() => {
    const script = document.createElement("script");
    script.src = process.env.VUE_APP_SALES_FORCE_SRC;
    document.head.appendChild(script);
  })();

  const initESW = ({ isGGPNL, lang, eswLiveAgentDevName, mySalesforceUrl, helpUrl, helpChatCode, helpChatId, deploymentId, buttonId, baseUrl}) => {
    window.embedded_svc.settings.displayHelpButton = true;
    window.embedded_svc.settings.enabledFeatures = ['LiveAgent'];
    window.embedded_svc.settings.entryFeature = 'LiveAgent';
    window.embedded_svc.settings.language = lang;
    window.embedded_svc.addEventHandler("afterMaximize", function () {
      const domainType = document.querySelector("#Domain_Type__c");
      const domainTypeValue = isGGPNL ? (document.querySelector("#Type") ? 'GGPokerNLHelpCase' : 'GGPokerNLHelpChat') : 'PlayGGHelp';

      if (!domainType) { return; }
      domainType.parentElement.style.display = 'none';
      domainType.value = domainTypeValue;
      domainType.dispatchEvent(new Event('change', { 'bubbles': !0 }));
    });
    window.embedded_svc.init(
      mySalesforceUrl,
      helpUrl,
      baseUrl,
      helpChatCode,
      helpChatId,
      {
        baseLiveAgentContentURL: 'https://c.la1-core1.sfdc-58ktaz.salesforceliveagent.com/content',
        deploymentId,
        buttonId,
        baseLiveAgentURL: 'https://d.la1-core1.sfdc-58ktaz.salesforceliveagent.com/chat',
        eswLiveAgentDevName,
        isOfflineSupportEnabled: true,
      }
    );
  };

  let intervalId = window.setInterval(() => {
    if (window.embedded_svc) {
      const isGGPNL = site === siteIds.GGPNL;
      const data = {
        isGGPNL,
        baseUrl: 'https://service.force.com',
        mySalesforceUrl: 'https://nsus.my.salesforce.com',
        helpChatCode: '00DDn0000013kss',
        lang: isGGPNL ? (baseLang === 'nl' ? 'nl_NL' : 'en') : (baseLang === 'ro' ? 'ro' : 'en'),
        helpUrl: isGGPNL ? 'https://help.ggpoker.nl' : 'https://help.playgg.ro',
        buttonId: isGGPNL ? '573OJ000000j0Bx' : '573OJ000000gOCP',
        helpChatId: isGGPNL ? 'Chat_GGPoker_NL_Help_External_No_Chat' : 'Chat_PlayGG_Help',
        deploymentId: isGGPNL ? '572OJ00000061cX' : '572Dn0000004bBD',
        eswLiveAgentDevName: isGGPNL ? 'EmbeddedServiceLiveAgent_Parent04IOJ0000000Esb2AE_1907c7e290f' : 'EmbeddedServiceLiveAgent_Parent04IOJ0000000Drh2AE_1902e9644be',
      };

      initESW(data);
      clearInterval(intervalId);
      intervalId = null;
    }
  }, 500);
};